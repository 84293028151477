<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>

<script>
import DefaultParams from "@/utils/defalut_params.js";
import Footer from "@/components/Footer/Footer";
export default {
  components: { Footer },
  created() {
    DefaultParams.init(this.$route.query);
  }
};
</script>

<style scoped>
#app {
  height: 100%;
  overflow: auto;
}
</style>
