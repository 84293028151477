<template>
  <header
    uk-sticky="cls-active: uk-navbar-sticky;"
    class="uk-sticky uk-navbar-sticky uk-sticky-below uk-sticky-fixed"
    style="position: fixed; top: 0px; right: 0; left: 0"
  >
    <nav class="uk-navbar-container uk-navbar" uk-navbar="">
      <div class="nav-overlay uk-navbar-left">
        <a
          class="uk-navbar-item uk-logo uk-padding-remove"
          style="color: #fff; font-weight: 900"
          href="/"
        >
          Makeover Master
        </a>
      </div>

      <div
        class="
          uk-navbar-item
          uk-hidden@m
          uk-padding-remove
          uk-position-center-right
          uk-position-z-index
          menu-button-container
        "
      >
        <a
          href="javascript:;"
          class="uk-link-reset uk-padding-small menu-button uk-icon"
          :class="open ? 'is-open' : ''"
          uk-icon="icon: icn-menu"
          uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
          aria-expanded="true"
          @click="clickShowTab"
          ><svg
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="2" rx="1" fill="#fff"></rect>
            <rect y="8" width="24" height="2" rx="1" fill="#fff"></rect>
            <rect y="16" width="24" height="2" rx="1" fill="#fff"></rect></svg
        ></a>
      </div>

      <div class="nav-overlay uk-navbar-right">
        <ul class="uk-navbar-nav uk-visible@m" :class="open ? 'active' : ''">
          <li
            style="margin-left: 32px"
            @click="clickToAbout"
            class="uk-flex-none"
          >
            <a href="#aboutUs">About Us</a>
          </li>
          <li class="uk-flex-none">
            <router-link to="/games">Games</router-link>
          </li>
        </ul>
      </div>

      <div
        class="nav-overlay uk-position-fixed uk-width-1-1"
        :hidden="open ? false : true"
      >
        <div class="uk-overflow-auto">
          <ul class="uk-navbar-nav uk-flex-nowrap">
            <li
              style="margin-left: 32px"
              @click="clickToAbout"
              class="uk-flex-none"
            >
              <a href="#aboutUs">About Us</a>
            </li>
            <li style="margin-left: 32px" class="uk-flex-none">
              <router-link to="/games">Games</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      fixed: "",
      open: false,
      mobileOpen: false
    };
  },
  methods: {
    clickShowTab() {
      this.open = !this.open;
    },
    clickToAbout() {
      if (this.$route.name === "Games") {
        this.$router.push({
          name: "Home"
        });
      }
    }
  }
};
</script>
