<template>
  <section id="games">
    <Header></Header>
    <main style="padding-top: 78px">
      <section
        class="
          uk-section
          uk-section-small
          uk-section-muted
          uk-padding-remove-vertical@m
        "
      >
        <div class="uk-container">
          <div class="uk-grid uk-grid-medium uk-flex-middle" uk-grid="">
            <div
              class="
                uk-width-2-5@m uk-text-center uk-text-left@m uk-first-column
              "
            >
              <h1 class="uk-margin-remove">Makeover Puzzle Game</h1>
              <div class="uk-visible@s uk-margin">
                <p class="p1">
                  MAKEOVER helpless clients and give them the confidence to
                  follow their dreams.
                </p>
                <p>DECORATE a person's room as well as their appearance!</p>
                DRAMA is everywhere, be ready to meet some extreme
                personalities!
                <p></p>
              </div>
            </div>

            <div class="uk-width-3-5@m">
              <img
                src="@/assets/img/4.jpg"
                alt="Book Of Madness Nobg 1"
                class="uk-width-1-1"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="uk-section uk-section-small uk-section-xlarge@m">
        <div class="uk-container">
          <div class="uk-width-5-6@m uk-margin-auto uk-text-primary">
            <a
              href="javascript:;"
              class="uk-display-block uk-position-relative uk-link-reset"
              uk-toggle=""
              aria-expanded="false"
            >
              <img
                src="@/assets/img/5.jpg"
                alt="Screen Shot 2021 05 25 At 22.20 1"
                class="uk-width-1-1 uk-border-rounded-xlarge"
              />
            </a>
          </div>
        </div>
      </section>

      <section
        id="detail"
        class="uk-section uk-section-small uk-section-muted uk-overflow-hidden"
      >
        <div class="uk-container">
          <div
            class="uk-width-5-6@m uk-margin-auto uk-text-center uk-text-left@m"
          >
            <div class="uk-grid uk-grid-medium uk-flex-middle" uk-grid="">
              <div class="uk-width-2-5@m uk-first-column">
                <div class="phone-container">
                  <img src="@/assets/img/6.jpg" alt="" width="286" />
                </div>
              </div>

              <div class="uk-width-3-5@m">
                <h2>Join the journey of Makeover</h2>
                <p></p>
                <p class="p1">
                  Hey there! Our show, Makeover Master, aims to give people a
                  new lease on life. But we've hit a hurdle - some of our
                  crucial team members have defected to our competitor, who's
                  dominating the ratings!
                </p>
                <p class="p1">
                  We need a game-changer! Join us as our creative director and
                  bring killer ideas to the table.
                </p>

                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </section>
</template>
<script>
import Header from "@/components/Header/index";

export default {
  components: {
    Header
  },
  mounted() {
    const scrollEle = document.scrollingElement;
    if (scrollEle) {
      scrollEle.scrollTop = 0;
    }
  }
};
</script>
