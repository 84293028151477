import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPage from "../views/PrivacyTerms/PrivacyPage.vue";
import TermsPage from "../views/PrivacyTerms/TermsPage.vue";
import Games from "../views/Games/Games.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPage
  },
  {
    path: "/games",
    name: "Games",
    component: Games
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsPage
  },
  {
    path: "/read-terms",
    name: "read-terms",
    component: Home
  }
];

const routerInstance = () => {
  // write name into path:
  const name = process.env.VUE_APP_NAME;
  const router = new VueRouter({
    mode: "history",
    base: window.__POWERED_BY_QIANKUN__
      ? `/micro/${name}/`
      : process.env.BASE_URL,
    routes
  });

  return router;
};

export default routerInstance;
