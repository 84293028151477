<template>
  <section>
    <h2 class="page-title">PRIVACY POLICY</h2>

    <PrivacyContent></PrivacyContent>
  </section>
</template>
<script>
import PrivacyContent from "@/components/PrivacyTerms/PrivacyContent";

export default {
  components: {
    PrivacyContent
  },
  mounted() {
    const scrollEle = document.scrollingElement;
    if (scrollEle) {
      scrollEle.scrollTop = 0;
    }
  }
};
</script>

<style scoped>
.page-title {
  font-size: 32px;
  text-align: center;
  padding: 64px 0 32px;
}
</style>
