<template>
  <section>
    <Header />
    <main>
      <section>
        <div
          class="uk-position-relative uk-slider uk-slider-container"
          tabindex="-1"
          uk-slider=""
        >
          <ul
            class="uk-slider-items uk-child-width-1-1"
            style="transform: translate3d(0px, 0px, 0px)"
          >
            <li tabindex="-1" class="uk-active" style="padding-top: 78px">
              <img :src="bannerImg" alt="" class="uk-width-1-1" />
            </li>
          </ul>
          <div
            class="
              uk-position-bottom-center uk-margin-medium-bottom uk-visible@m
            "
          >
            <a
              href="#about"
              class="uk-icon-button uk-preserve uk-icon"
              uk-icon="icon: icn-arrow-down"
              uk-scroll="offset: 80;"
              @click="scrollPage"
              ><svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26 17L24.4137 15.4138L18.125 21.6912V8H15.875V21.6912L9.5975 15.4025L8 17L17 26L26 17Z"
                  fill="#1E1E1E"
                ></path></svg
            ></a>
          </div>
        </div>
      </section>

      <section id="aboutUs" class="uk-section uk-section-large">
        <div class="uk-container uk-text-center">
          <div
            class="uk-width-2-3@m uk-margin-auto uk-margin-remove-last-child"
          >
            <h1 class="uk-h2">About Us</h1>
            <p></p>
            <p>
              A Young company made with several young engineers and designers
              that want to change the world with their dedication and attention
              to detail on the games.
            </p>

            <p></p>
            We are passionate about great product experiences and believe that
            better games are essential for creativity and amazing results.
            <p></p>
          </div>
        </div>
      </section>

      <section
        class="uk-section uk-section-small uk-section-muted uk-overflow-hidden"
      >
        <div class="uk-container">
          <div class="uk-grid uk-grid-medium uk-flex-middle" uk-grid="">
            <div
              class="
                uk-width-1-3@m uk-margin-auto-left uk-text-center uk-flex-last@m
              "
            >
              <div
                class="phone-container"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/img/3.jpg"
                  alt="Iphone12 Screen"
                  width="286"
                />
              </div>
            </div>

            <div
              class="
                uk-width-2-5@m uk-text-center uk-text-left@m uk-first-column
              "
            >
              <h2>Makeover Story</h2>

              <p class="p1">
                Give desperately needed makeovers to help people achieve their
                dreams! Choose highly fashionable clothes, hairstyles, makeup,
                and even furniture! Deal with dramatic characters like
                egotistical fashion icons, scheming assistants, or stubborn
                clients in dire need of a new wardrobe.
              </p>

              <p></p>

              <div
                class="
                  uk-margin-medium
                  uk-width-medium-max
                  uk-margin-auto
                  uk-margin-remove-left@m
                "
              >
                <div
                  class="
                    uk-grid
                    uk-grid-small
                    uk-child-width-1-2
                    uk-flex-center
                    uk-flex-left@m
                    uk-flex-middle
                  "
                  uk-grid=""
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="uk-section uk-section-small uk-padding-remove-bottom@m"
        style="padding-bottom: 48px"
      >
        <div class="uk-container">
          <div class="uk-grid uk-grid-medium uk-flex-middle" uk-grid="">
            <div
              class="uk-width-1-2@m uk-first-column"
              style="padding-bottom: 32px"
            >
              <img
                src="@/assets/img/2.jpg"
                alt="Main Careers 1"
                width="526"
                style="border-radius: 4.7% / 11%"
              />
            </div>

            <div
              class="
                uk-width-1-3@m uk-margin-auto-left uk-text-center uk-text-left@m
              "
            >
              <h2>Addictive Puzzles</h2>
              <div class="uk-margin-remove-last-child">
                <p></p>
                <p>
                  Solve addictive puzzles with obstacles that will put your
                  skills to the test. Utilize power-ups to overcome levels and
                  enhance your enjoyment of the game. Blast through levels with
                  exciting power-ups with explosive benefits!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </section>
</template>

<script>
import Header from "@/components/Header/index";
export default {
  name: "Home",
  components: {
    Header
  },
  data() {
    return {
      bannerImg: require("@/assets/img/dream-banner.jpg")
    };
  },
  methods: {
    scrollPage() {
      const scrollEle = document.scrollingElement;
      if (scrollEle) {
        scrollPage.scrollTo(600);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter");
    next(vm => {
      vm.$nextTick(() => {
        const aEle = document.querySelector("#aboutUs");
        if (aEle) {
          aEle.scrollIntoView();
        }
      });
    });
  },
  mounted() {
    const scrollEle = document.scrollingElement;
    if (scrollEle) {
      scrollEle.scrollTop = 0;
    }
    this.$nextTick(() => {
      this.bannerImg =
        window.screen.availWidth < 500
          ? require("@/assets/img/750x860.jpg")
          : require("@/assets/img/dream-banner.jpg");
    });
  }
};
</script>

<style scoped>
.home-section {
  height: 100%;
}

#comp-kx685r6w {
  visibility: hidden !important;
}
</style>
