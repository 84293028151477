<template>
  <footer
    class="
      uk-section
      uk-section-small
      uk-section-primary
      uk-text-center
      uk-text-left@m
    "
  >
    <div class="uk-container">
      <div class="uk-grid uk-grid-medium" uk-grid="">
        <div
          class="uk-width-1-3@m uk-first-column"
          style="color: #fff; font-size: 24px; font-weight: 900"
        >
          <a href="/"> Makeover Master </a>

          <div
            class="
              uk-margin
              uk-text-14
              uk-visible@m
              uk-position-relative
              uk-position-z-index
            "
          >
            <!-- <a href="mailto:mailto:support@makeoverfun.com"
              >mailto:support@makeoverfun.com</a
            > -->
          </div>
        </div>

        <div class="uk-width-1-3@m">
          <ul class="uk-subnav uk-flex-center">
            <li v-if="!isHome"><router-link to="/">Home</router-link></li>
            <li><a href="mailto:support@makeoverfun.com">Contact</a></li>
            <li><router-link to="/privacy">Privacy</router-link></li>
            <li><router-link to="/terms">Terms</router-link></li>
          </ul>
        </div>

        <div
          class="
            uk-width-1-1
            uk-text-center
            uk-flex
            uk-flex-column
            uk-flex-middle
            uk-margin-remove
          "
        >
          <small class="uk-display-block"
            >Copyright © {{ yearTime }} - Makeover Master</small
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  },
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>
<style scoped>
.contact-list {
  margin-right: 16px;
}
</style>
